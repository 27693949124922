.specificCustomer_select {
    min-width: 330px;
}

/* @media(max-width: 1024px) {
    .custom-flex{
        flex-wrap: wrap;
        justify-content: start !important;
    }
    .custom-flex > .w-full{
        width: 32.33%;
    }
    .space-x-5 > :not(template) ~ :not(template){
        margin-left: 0 !important;
    }

    .radio-box label{
        margin-right: 15px;
    }
}

@media(max-width: 991px) {
    .custom-flex > .w-full{
        width: 49%;
    }
}

@media(max-width: 576px) {
    .custom-flex > .w-full{
        width: 100%;
    }
} */


@media(max-width: 576px) {
    .w-full {
        margin-right: 0 !important;
        margin-left: 0 !important;
    }
}

.downArrow {
    position: absolute;
    right: 10px;
    top: 21px;
}

.mt-0 {
    margin-top: 0 !important;
}