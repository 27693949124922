.addRoleCss {
  position: relative;
  float: right;
  margin: 35px 35px;

  top: 63px;
}

.addBack {
  text-align: right;
  padding: 10px 0;
  /* float: right; */
  /* margin: -45px 0px; */
}

.addBack,
:link {
  /* padding: 10px 30px; */
}

.addSubmit {
  background-color: #28c76f;
  margin: 10px;
  border-radius: 10px;
}

.inputCss {
  display: inline-flex !important;
}

.badge {
  display: inline-block;
  padding: 0.3rem 0.5rem;
  font-size: 85%;
  font-weight: 600;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.358rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, background 0s,
    border 0s;
}

.badge-success {
  color: #fff;
  background-color: #28c76f;
}

.badge {
  color: #fff;
}

.badge-danger {
  color: #fff;
  background-color: #ea5455;
}

.searchCss {
  width: 305px;
  height: 40px;
  border-radius: 43px;
  background-color: #fafafa;
  padding: 10px;
  border-width: 1px;
  border-color: #1d1d1d;
}

.searchCss.product_type_input , .searchCss.add_fliter_input{
  width: auto;
}

.inputBoxCategoryCss {
  width: 500px;
  height: 50px;
}

.inputFields {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.inputFilterFields {
  width: 150px;
  padding: 10px 10px;
  margin: 0px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.addCategoryCss {
  float: right;
  position: relative;
  padding: 10px 20px;
  top: 10px;
  left: -20px;
}

.SelctOptioncategory {
  border: 1px solid #ccc;
  width: 500px;
  height: auto;
  background-color: white;
  padding: 12px 11px;
  margin-left: 18px;
  border-radius: 4px;
  color: gray;
  /* box-sizing: border-box; */

}

.form-label {
  padding-left: 14px;
}

.addBackButton {

  position: absolute;
  top: 207px;
  right: 58px;
}

.addFilterCss {
  position: relative;
  float: right;
  margin: 15px 35px;
  top: -12px;
}

.masterFilterCss {
  width: 180px;
  padding: 10px;
}

.filterCategoryCss {
  text-align: center;
  font-weight: bold;
}


ul.pagination {
  display: flex;
  padding: 0;
  margin: 0;
  justify-content: center;
}

ul.pagination li a {
  font-size: 14px;
  position: relative;
  padding: 0.375rem 0.75rem;
  display: block;
  color: #0d6efd;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #dee2e6;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

ul.pagination li.active .undefined {
  background-color: #0d6efd;
  color: white;
  border-radius: 0px;
  border-color: transparent;
}

ul.pagination li:first-child a {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

ul.pagination li:last-child a {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

ul.pagination li a:hover:not(.active) {
  background-color: #ddd;
  color: #0d6efd;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #2196f3;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #2196f3;
  border-radius: 6px;
}

.flex_box {
  display: flex;
}

.flex_box .form-element.form-element {
  width: 50%;
  padding-right: 1%;
  position: relative;
}

.flex_box .form-element:last-child {
  padding-right: 0%;
}

.flex_box .form-element:first-child {
  padding-right: 1%;
}

.flex_box .form-element input {
  width: 100% !important;
}

.flex_box .form-element label input {
  width: auto !important;
}

.inputTag>div {
  width: 100% !important;
  min-height: 40px;
  background: #fff;
  /* border: 0 !important; */
}

.inputTag div input {
  /* height: 40px; */
}

.flex_box .form-element.w-100 {
  width: 100%;
}

.underLineText {
  text-decoration: underline;
}

.SelctOptionscategory {
  border: 1px solid #ccc;
  width: 500px;
  height: auto;
  background-color: white;
  padding: 12px 11px;
  margin-left: 18px;
  border-radius: 4px;
  margin: 10px;
  color: gray;
  /* box-sizing: border-box; */

}

.faq_box {
  background-color: #fafafa;
  border: 1px solid #ddd;
  padding: 15px;
  margin: 0 0 20px;
  border-radius: 6px;
}

.faq_box .faq_list {
  padding: 10px;
  border-radius: 5px;
  margin: 0 0 10px;
  background-color: #fff;
  box-shadow: 0 2px 8px 0px rgba(0, 0, 0, .1);
}

.faq_box .faq_list:last-child {
  margin: 0;
}

.multiselect-dropdown {
  width: auto !important;
  display: flex;
  flex-direction: column;
  padding: 15px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.dropdown-box {
  top: 32px !important;
}

.seprate-radio {
  justify-content: space-between;
  margin-bottom: 30px;
}

.seprate-radio .spearte_inner {
  width: 50%;
}

.years_history {
  align-items: center;
}

.removeCss {
  width: 20px;
  height: 20px;
  /* position: relative;
  top: -147px;
  left: 127px; */
  /* idth:"20px",
  height:"20px",position:"relative",top:"-147px",left:"127px" */
  cursor: pointer;
}

.image-upload {
  border: 1px solid #e6e6e6;
  margin-bottom: 10px;

}

.image-upload,
.image-upload label {
  width: 150px !important;
  height: 150px;
  display: block;
  text-align: center;
  line-height: 145px;
  border-radius: 5px;
  transition: .2s ease-in-out;
  cursor: pointer;
}

.image-upload label:hover {
  background-color: #e6e6e6;
}

.image-upload label img {
  display: inline-block;
}

.mb-15 {
  margin-bottom: 15px;
}

.img-box {
  position: relative;
  border: 1px solid #e6e6e6;
  width: 150px;
  height: 150px;
  border-radius: 5px;
  overflow: hidden;
}

.img-box .img {
  object-fit: cover;
  height: 100%;
  width: 100%
}

.img-box .removeCss {
  position: absolute;
  right: 5px;
  top: 5px;
}

.product-gallery {
  flex-wrap: wrap;
}

.position-relative {
  position: relative;
}

.currency-box .currency-name {
  position: absolute;
  top: 21px;
  left: 12px;
}

.currency-box input {
  padding: 12px 15px 12px 50px;
}

.currency-box select {
  position: absolute;
  width: 130px;
  right: 1px;
  top: 1px;
  border: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
}

.selectWithSearch {
  /* border: 1px solid #ccc; */
  width: 500px;
  height: auto;
  background-color: white;
  margin-left: 18px;
}

.tagBox .inputTag>div {
  border-radius: 4px;
}


.image-upload1 {
  border: 1px solid #e6e6e6;
  margin-bottom: 10px;

}

.inputTag>.image-upload1,
.image-upload1 label {
  width: 150px !important;
  height: 150px;
  display: block;
  text-align: center;
  line-height: 145px;
  border-radius: 5px;
  transition: .2s ease-in-out;
  cursor: pointer;
  display: inline-block;
}

.image-upload1 label:hover {
  background-color: #e6e6e6;
}

.image-upload1 label img {
  display: inline-block;
}

.img-box1 {
  position: relative;
  border: 1px solid #e6e6e6;
  width: 150px !important;
  height: 150px;
  border-radius: 5px;
  overflow: hidden;
}

.img-box1 .img {
  object-fit: cover;
  height: 100%;
  width: 100%
}

.img-box1 .removeCss {
  position: absolute;
  right: 5px;
  top: 5px;
}

.inline_b {
  display: flex;

}

.form-element {
  position: relative;
  width: 48%;
}

.minTh th {
  min-width: 150px;
}

.minTh th:first-child {
  min-width: auto;
}

/* Rahul*/

.commission_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 15px;
}

.commission_box .dropDwonVendor {
  max-width: 125px;
  /* width: 100%; */
}

.dropDwonVendor2 {
  max-width: 500px;
  width: 100%;


}

.commission_box .dropDwonVendor2 select {
  border: 1px solid #eeeeee;
  width: 100%;
  padding: 5px;
  border-radius: 5px;
}

.commission_box .dropDwonVendor select {
  border: 1px solid #eeeeee;
  width: 100%;
  padding: 5px;
  border-radius: 5px;
}

.commission_box1 {
  display: flex;
  justify-content: space-between;
  align-items: center;

}

.commission_box1 .dropDwonVendor1 select {
  border: 1px solid #eeeeee;
  width: 100%;
  padding: 5px;
  border-radius: 5px;
}

/* border: 2px solid lightgray;
    height: 35px;
    width: 160px; */

input[type="text"]:disabled {
  background: #efefef;
}

.addBack .cancel_btn {
  border-radius: 5px;
  display: inline-block;
  margin-right: 10px;
}


@media (max-width: 991px){
  .add_filter_input_Box{
    display: flex;
    flex-wrap: wrap;
  }

  .add_filter_input_Box .inputTag {
    width: 33.33%;
  }

  .static-label{
    margin-left: 0 !important;
  }
  
  .editor_state {
    margin-left: 0 !important;
  }

  .vendor-flex-box .form-element{
    width: 33.33%;
  }

  .vendor-flex-box.add_sub_adminBox .form-element{
    width: 33.33%;
  }
}


@media (max-width: 576px){
  .sales_night .flex_open_sale .space-x-40{
    flex-direction: column;
    width: 100%;
  }

  .sales_night .flex_open_sale p {
    margin-bottom:20px;
  }


  .sales_night .flex_open_sale div{
    width: 100% !important;
    margin:auto;
  }
  .searchCss{
    width: 97% !important;
  }

  .addRoleCss{
    margin: 0px 10px;
    top: 105px;
  }

  .dashbord_todo_task_box .todo_inner_box{
    align-items: baseline;
  }

  .vendor-flex-box .form-element{
    width: 100%;
  }

  .vendor-flex-box.add_sub_adminBox .form-element{
    width: 100%;
  }

  .customer_review_btn_box{
    flex-wrap: wrap;
  }

  .customer_review_btn_box button{
    width: auto;
  }

  .customer_review_btn_box button{
    margin-top: 15px;
  }

  .customer_review_btn_box .review_input_box{
    width: auto;
    margin-top: 15px;
  }

  .vendors_btn_box{
    flex-wrap: wrap;
    justify-content: center;
    padding: 0 !important;
  }

  .vendors_btn_box button{
    width: 42%;
    margin-right: 8px;
    margin-top: 10px;
  }

  .searchCss.vendor_input{
    width: 100% !important;
  }

  .flex_box.edit_vendor_box{
    width: 100%;
    display: block;
  }

  .flex_box.edit_vendor_box .form-element{
    width: 100%;
    margin-bottom: 8px;
  }

  .flex_box.edit_vendor_box .form-element .inputFields{
    margin-top: 6px !important;
  }

  .flex_box.edit_vendor_box .form-element .web_level_box{
    margin: 0px !important;
  }

  .flex_box.years_history{
    display: block;
  }

  .flex_box.years_history .form-element{
    width: 100%;
  }
  
  .flex_box.years_history .form-element .inputFields{
    margin-top: 6px !important;
    margin-bottom: 0px !important;
  }

  .flex_box.seprate-radio{
    display: block;
  }

  .flex_box.seprate-radio .spearte_inner{
    width: 100%;
    margin: 10px 0;
  }

  .edit_vendor_btnBox {
    justify-content: center !important;
  }

  .edit_vendor_btnBox button{
    max-width: 67px !important;
    padding: 8px 10px !important;
    font-size: 11px !important;
  }

  .commision_edit_modal .commision_edit_modalBox{
    width: 365px;
  }

  .commision_edit_modal .modal-content .inputBoxCategoryCss{
    width: auto;
  }

  .vendor_sub_btn_box .vendor_btn button{
    margin: 15px 0 0 8px;
    font-size: 11px;
    padding: 10px 8px;
  }

  .searchCss.manage_input{
    width: 100% !important;
    margin-bottom: 15px;
  }

  .manage_product_box{
    width: 100%;
  }
  
  .manage_product_box .masterFilterCss{
    margin-bottom: 5px !important;
  }

  .manage_product_box .filterCategoryCss{
    text-align: left;
  }

  .manage_product_box .masterFilterCss {
    width: 100%;
  }

  .addCategoryCss{
    left: unset;
    top: 0;
    right: 0;
  }

  .addFilterCss{
    top: 2px !important;
    margin: 0;
  }

  .modal .modal-content .inputBoxCategoryCss{
    width: 100%;
  }

  .modal .modal-content .selectWithSearch{
    width: 100%;
    margin-left: 0;
    padding: 0 1rem;
  }
  .modal .modal-content .modal-header h3 
  {
    font-size: 16px;
  }

  .searchCss.product_type_input{
    margin-bottom: 15px;
  }

  .faq_input_btn_sec{
    flex-wrap: wrap;
  }

  .faq_input_btn_sec .input_btn_box{
    width: 100%;
    margin-bottom: 15px;
  }

  .faq_input_btn_sec .btn_box{
    text-align: center;
    display: block;
    width: 100%;
  }

  .faq_input_btn_sec .searchCss{
    display: block;
    width: 100% !important;
  }

  .add_faq_modal .add_faq_modal_box .SelctOptionscategory{
    width: auto;
  }

  .add_faq_cat_modal .add_faq_cat_modal_box{
    width: 100%;
    margin: 0 10px;
  }

  .add_faq_cat_modal .add_faq_cat_modal_box .faq_box_inner{
    max-height: 375px;
    overflow-y: auto;
  }

  .searchCss.add_fliter_input{
    margin-bottom: 15px !important;
  }

  .add_att_btn{
    height: 38px;
  }

  .terms_container{
    padding: 10px !important;
  }
  .static-label{
    margin-top: 10px !important;
  }

  .editor_state{
    margin-top: 15px !important;
  }

  .editor-class{
    padding: 10px 8px !important;
  }

  .editor-class .DraftEditor-root h2{
    font-size: 20px;
  }

  .static-container .saveButton{
    height: 38px !important;
    max-width: 100px !important;
    width: 100% !important;
    margin: 20px 0px 0px 5px !important;
  }

  .message_section .chat_box .innerBox{
    flex-wrap: wrap;
  }

  .message_section .chat_box .innerBox .chat_list{
    max-width: none !important;
  }

  .message_section .chat_box .innerBox .chat_desc{
    border-left: none !important;
    border-top: 1px solid rgba(112, 112, 112, 0.231372549);
  }

}

@media (max-width: 375px){
  .customer_review_btn_box .review_input_box{
    width: 100%;
    height: 35px;
  }

  .customer_review_btn_box .review_input_box input{
    width: 100%;
  }

  .terms_condition_title{
    font-size: 18px;
  }
  
}

@media (max-width: 374px){
  .commision_edit_modal .commision_edit_modalBox{
    width: 310px;
  }

  .add_att_btn{
    padding: 6px 5px !important;
    height: 38px;
  }

  .add_faq_modal .add_faq_modal_box{
    width: 305px;
  }

  .faq_input_btn_sec .btn_box button{
    padding: 8px;
  }

  .sub_cate_flex{
    flex-wrap: wrap !important;
  }

  .add_sub_cat_btn{
    padding: 8px 6px !important;
    font-size: 10px !important;
  }

  .addCategoryCss{
    padding: 8px 6px !important;
    font-size: 11px !important;
    top: 35px;
    right: -10px;
  }

  .vendor_sub_btn_box{
    display: block !important;
  }

  .vendor_sub_btn_box .searchCss{
    width: 100% !important;
    margin-bottom: 10px;
  }

  .searchCss.add_fliter_input, .searchCss.product_type_input, .sub_cate_input{
    max-width: 150px;
  }

  .addFilterCss {
    padding: 10px 5px !important;
  }

}

.editor_state .wrapper-class{
  width: auto;
}
.text-gray-600 {
  --tw-text-opacity: 1 !important;
  color: rgb(75 85 99/var(--tw-text-opacity)) !important;
}
.scrollbar-thumb-bg-eunry {
  --scrollbar-thumb: #dba8a1!important;
}
.scrollbar-thin {
  --scrollbar-track: initial;
  --scrollbar-thumb: initial;
  scrollbar-color: var(--scrollbar-thumb) var(--scrollbar-track);
  overflow: overlay;
}
menu, ol, ul {
  list-style: none!important;
  margin: 0!important;
  padding: 0!important;
}
.text-sm {
  font-size: .875rem!important;
  line-height: 1.25rem!important;
}
.px-3 {
  padding-left: 0.75rem!important;
  padding-right: 0.75rem!important;
}
.py-2 {
  padding-top: 0.5rem!important;
  padding-bottom: 0.5rem!important;
}
.border-gray-300 {
  --tw-border-opacity: 1!important;
  border-color: rgb(209 213 219/var(--tw-border-opacity))!important;
}
.border-b {
  border-bottom-width: 1px!important;
}
.items-center {
  align-items: center!important;
}
.min-w-full {
  min-width: 100%!important;
}
.pb-2 {
  padding-bottom: 0.5rem !important;
}

.w-full {
  width: 100% !important;
}

.h-\[60vh\] {
  height: 60vh !important;
}

/* ------------------------Chat Custom Css------------------------------ */
.h-cus.h-\[60vh\] {
  height: calc( 100vh - 345px ) !important;
}

.min-w-full.border{
  background-color: #fff;
}

.bg-color{
  background-color: #f3f4f6;
}

.chat-font{
  font-size: 15px !important;
}

.list-font .font-worksans-medium{
  font-size: 14px;
  font-weight: 600;
}

.list-font .text-sm{
  font-size: 13px !important;
}

.scrollbar-thumb-bg-eunry img {
  margin: 0 auto;
  /* max-width: 200px; */
}

.chat-list {
  background-color: #f3f4f6;
}

.chat-list .text-lg {
  font-size: 14px !important;
}

.chat-box{
  background-color: #f3f4f6;
}

.ticket-box .flex.justify-between .font-worksans-medium{
  font-weight: 600;
  color: #888;
}

.ticket-box .flex.justify-between {
  font-size: 13px;
}

.svg-icon-storke svg{
  stroke: #6b7280 !important;
} 
.svg-icon-fill svg{
  fill: #6b7280 !important;
} 
.border-left{
  border-left: 1px solid #d1d5db;
}

.cus-height-vh{
  min-height: calc(100vh - 66px) !important;
}

/* .scrollbar-thin.scrollbar-thumb-bg-eunry.scrollbar-track-bg-romance-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: rgba(251, 216, 85, 0.2);
} */
/* .scrollbar-thin.scrollbar-thumb-bg-eunry.scrollbar-track-bg-romance::-webkit-scrollbar {
  width: 8px;
} */

/* .scrollbar-thin.scrollbar-thumb-bg-eunry.scrollbar-track-bg-romance::-webkit-scrollbar-thumb {
  background-color: #fbd855;
  border-radius: 40px;
} */

.scrollbar-thin.scrollbar-thumb-bg-eunry.scrollbar-track-bg-romance::-webkit-scrollbar {
  display: block;
  width: 4px !important;
}

.scrollbar-thin.scrollbar-thumb-bg-eunry.scrollbar-track-bg-romance .list-font{
  height: calc(100vh - 300px);
}

.hide {
  display: none;
}


.emoji-parent-box{
  position: relative;
}

.emoji-parent-box .emoji_child_box{
  position: absolute;
  bottom: 100%;
  left: 10px;
  width: 50%;
}

aside.EmojiPickerReact.epr-main{
  box-shadow: 3px 5px 8px 0 rgba(0, 0, 0, 0.1)
}

.unread-box span{
  display: inline-block;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background: green;
  color: #fff;
  font-size: 12px;
  text-align: center;
}

.text-ellipsis {
  display: inline-block;
  width: 160px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.public-DraftStyleDefault-block{
  margin: 0 !important;
}