.hover-container {
  position: relative;
  display: inline-block;
}

.hover-button {
  padding: 10px 20px;
  background-color: #3b82f6;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.hover-button:hover {
  background-color: #2563eb;
}

.hover-info {
  position: absolute;
  left: 60px;
  top: 0;
  /* margin-left: 55px; */
  width: 250px;
  background-color: #1f2937;
  color: white;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  z-index: 1000;
  text-transform: none;
}

.hover-container:hover .hover-info {
  opacity: 1;
  visibility: visible;
}
