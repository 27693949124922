.wrapper-class {
    padding: 1rem;
    //border: 1px solid #ccc;
    background: #ffffff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 12px;
    width: 1064px;
  }
  .editor-class {
    // background-color: lightgray;
    padding: 1rem;
    // border: 1px solid #ccc;
    background: #ffffff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 12px;
  }
  // .toolbar-class {
  //   border: 1px solid #ccc;
  // }
  .static-container{
      background-color: #E5E5E5;
      .saveButton{
        height: 50px;
        width: 200px;
        margin: 40px 0px 0px 23px;
        border-radius: 10px;
        color: white;
      background-color: #2196f3;
     border: none;
      box-shadow: none;
      font-weight: 600;
      }
  }
  .editor_state {
    margin-top: 36px;
    margin-left: 22px;
  }
  
  .static-label {
    margin-top: 30px;
    margin-left: 27px;
  }
  
  .page_title {
    margin-left: 21px;
    margin-top: 46px;
    text {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      /* identical to box height */
  
      color: #3e3c3d;
    }
    input {
      background: #ffffff;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
      border-radius: 12px;
      width: 1095px;
      height: 76px;
      margin-top: 10px;
      border: none;
      padding-left: 20px;
      
    }
   
   
  }
  
  .table-responsive{
    overflow: auto;
    width: 100%;
  }
  
  .nav-menu-item { padding: 38px 0px; }

.mega-menu-wrap::-webkit-scrollbar { width: 2px; }
.mega-menu-wrap::-webkit-scrollbar-track { background: #f1f1f1; }
.mega-menu-wrap::-webkit-scrollbar-thumb { background: #1e88e5; }
.mega-menu-wrap::-webkit-scrollbar-thumb:hover { background: #196fbb; }
.left-full {left:100%}
.w-500px { width: 500px; }
.max-h-50vh {max-height: 50vh;}