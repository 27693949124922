@media (max-width: 767px) {
  .arrow {
    transform: rotate(90deg);
    transition: transform 0.15s ease-in-out;
  }

  .hidden-sibling {
    + ul {
      @apply hidden;
    }
    &.left-sidebar-item {
      .arrow {
        transform: rotate(-90deg) !important;
      }
    }
  }

  .left-sidebar-1 {
    .logo {
      @apply uppercase font-bold text-base tracking-wider flex flex-row items-center justify-start h-16 w-full whitespace-no-wrap pl-4;
      max-width: calc(theme('spacing.1') * 64);
      @apply text-blue-500;
      span {
        @apply block;
      }
    }
  }

  .left-sidebar-item {
    @apply flex flex-row h-8 items-center justify-start relative whitespace-no-wrap;
    min-width: calc(theme('spacing.1') * 16);
    max-width: calc(theme('spacing.1') * 64);
    width: calc(theme('spacing.1') * 64);
    > svg {
      width: calc(theme('spacing.1') * 16);
    }
    &.open-sibling {
      @apply text-blue-500;
      &:after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 4px;
        @apply bg-blue-500;
      }
    }
    &:after {
      content: "";
      display: none;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 4px;
      @apply bg-blue-500;
    }
    &:hover,
    &.active {
      @apply text-blue-500;
      &:after {
        display: block;
      }
    }
  }

  .l0 {
    > .left-sidebar-item {
      > .title {
        @apply visible opacity-100;
      }
    }
  }

  .left-sidebar-title {
    @apply uppercase font-normal text-xs tracking-wider flex flex-row items-center justify-start h-16 w-full px-4;
    @apply visible opacity-100;
  }

  ul ul .left-sidebar-item {
    padding-left: calc(theme('spacing.1') * 16);
  }
  ul ul ul .left-sidebar-item {
    padding-left: calc(theme('spacing.1') * 20);
  }
  ul ul ul ul .left-sidebar-item {
    padding-left: calc(theme('spacing.1') * 24);
  }
  ul ul ul ul ul .left-sidebar-item {
    padding-left: calc(theme('spacing.1') * 28);
  }

  .left-sidebar-1 {
    @apply text-sm shadow-lg;
    [data-left-sidebar="light"] & {
      @apply bg-white border-r border-grey-200 text-grey-600;
    }
    [data-left-sidebar="dark"] & {
      @apply border-r text-white;
      background: color(theme('colors.grey.900') tint(5%));
      border-color: color(theme('colors.grey.900') tint(10%));
    }
    .badge {
      @apply hidden !important;
    }
  }
}

@media (min-width: 768px) {
  .arrow {
    transform: rotate(90deg);
    transition: transform 0.15s ease-in-out;
    [data-collapsed="true"] & {
      @apply hidden;
    }
  }

  [data-collapsed="false"] {
    .hidden-sibling {
      + ul {
        @apply hidden;
      }
      &.left-sidebar-item {
        .arrow {
          transform: rotate(-90deg) !important;
        }
      }
    }
  }
  [data-collapsed="true"] {
    .hidden-sibling {
      + ul {
        @apply block;
      }
    }
  }

  .left-sidebar-1 {
    .logo {
      @apply uppercase font-bold text-base tracking-wider flex flex-row items-center justify-start h-16 w-full whitespace-no-wrap pl-4;
      max-width: calc(theme("spacing.1") * 64);
      @apply text-blue-500;
      span {
        transition: all 0.15s ease-in-out;
      }
      [data-collapsed="false"] & {
        span {
          @apply visible opacity-100;
        }
      }
      [data-collapsed="true"] & {
        max-width: calc(theme("spacing.1") * 16);
        span {
          @apply invisible opacity-0;
        }
      }
    }
  }

  .left-sidebar-item {
    @apply flex flex-row h-8 items-center justify-start relative whitespace-no-wrap truncate w-full;
    min-width: calc(theme("spacing.1") * 16);
    max-width: calc(theme("spacing.1") * 64);
    width: calc(theme("spacing.1") * 64);
    > svg {
      width: calc(theme("spacing.1") * 16);
    }
    [data-collapsed="true"] & {
      width: calc(theme("spacing.1") * 16);
      > .title {
        width: 0;
      }
    }
    &.open-sibling {
      @apply text-blue-500;
      &:after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 4px;
        @apply bg-blue-500;
      }
    }
    &:after {
      content: "";
      display: none;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 4px;
      @apply bg-blue-500;
    }
    &:hover,
    &.active {
      @apply text-blue-500;
      &:after {
        display: block;
      }
    }
  }

  [data-collapsed="false"] {
    .l0 {
      @apply truncate;
      > .left-sidebar-item {
        > .title {
          @apply visible opacity-100;
        }
      }
    }
  }
  [data-collapsed="true"] {
    .l0 {
      > .left-sidebar-item {
        > .title {
          @apply invisible opacity-0;
        }
      }
    }
  }

  .left-sidebar-title {
    @apply uppercase font-normal text-xs tracking-wider flex flex-row items-center justify-start h-16 w-full px-4 relative;
    &::before {
      transition: opacity 0.3s ease-in-out;
      display: block;
      opacity: 0;
      content: "";
      position: absolute;
      z-index: 1;
      top: calc((theme("spacing.1") * 8) - 2px);
      left: 0;
      right: 0;
      height: 1px;
      width: 100%;
    }
    [data-left-sidebar="light"] & {
      &::before {
        @apply bg-grey-200;
      }
    }
    [data-left-sidebar="dark"] & {
      &::before {
        @apply bg-grey-800;
      }
    }
    [data-collapsed="true"] & {
      &::before {
        opacity: 1;
      }
    }
    span {
      transition: opacity 0.15s ease-in-out;
      [data-collapsed="false"] & {
        @apply visible opacity-100;
      }
      [data-collapsed="true"] & {
        @apply invisible opacity-0;
      }
    }
  }

  [data-collapsed="false"] {
    ul ul .left-sidebar-item {
      padding-left: calc(theme("spacing.1") * 16);
    }
    ul ul ul .left-sidebar-item {
      padding-left: calc(theme("spacing.1") * 20);
    }
    ul ul ul ul .left-sidebar-item {
      padding-left: calc(theme("spacing.1") * 24);
    }
    ul ul ul ul ul .left-sidebar-item {
      padding-left: calc(theme("spacing.1") * 28);
    }
  }

  .left-sidebar-1 {
    @apply text-sm;
    [data-left-sidebar="light"] & {
      @apply bg-white border-r border-grey-200 text-grey-600;
    }
    [data-left-sidebar="dark"] & {
      @apply border-r text-white;
      background: color(theme("colors.grey.900") tint(5%));
      border-color: color(theme("colors.grey.900") tint(10%));
    }
    .badge {
      @apply hidden !important;
    }
    [data-collapsed="true"][data-left-sidebar="light"] & {
      ul > li {
        &:not(.l0) {
          @apply bg-white;
        }
        > ul {
          @apply bg-white;
        }
      }
    }
    [data-collapsed="true"][data-left-sidebar="dark"] & {
      ul > li {
        &:not(.l0) {
          background: color(theme("colors.grey.900") tint(5%));
        }
        > ul {
          background: color(theme("colors.grey.900") tint(5%));
        }
      }
    }
    [data-collapsed="true"] & {
      ul > li {
        @apply relative;
        &.l0 {
          > .left-sidebar-item {
            > .title {
            }
          }
        }
        &:not(.l0) {
          @apply w-48;
          > .left-sidebar-item {
            @apply flex flex-row h-8 w-48 items-center justify-start relative px-3;
          }
        }
        > ul {
          @apply absolute top-0 w-48 shadow-lg z-10 invisible opacity-0 cursor-none;
          left: 100%;
        }
        &:hover {
          > ul {
            @apply visible opacity-100 cursor-auto;
          }
        }
      }
    }
  }
}

[data-left-sidebar="light"] {
  .left-sidebar {
    @apply text-grey-900;
    @apply bg-white border-grey-100;
  }
}

[data-left-sidebar="dark"] {
  .left-sidebar {
    @apply text-white;
    background: color(theme('colors.grey.900') tint(5%));
    border-color: color(theme('colors.grey.900') tint(10%));
  }
}

